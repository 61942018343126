<!--
 * @Author: your name
 * @Date: 2020-11-10 12:54:13
 * @LastEditTime: 2020-12-03 09:59:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /NewCunwUaregoodWebSite/src/views/OS/newsDetails10.vue
-->
<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>两山”崛起“头脑产业” —长沙高质量发展调研行</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2019-05-09
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class="img-box" style="margin-top:40px">
          <img src="../../assets/images/news/news10/news1.png" alt="">
        </div>
        <div class="news-content" style="margin:20px 0 40px">
          <p style="text-align:justify;">
            实现高质量发展，需要形成良好的创新生态，促进各种资源要素协同共生。探究长沙市经济高质量发展，不难发现，这里除了拥有雄厚的制造业基础，还拥有强大的软实力——优良的营商环境与浓厚的创新创业氛围。
          </p>
          <p style="text-align:justify;">
            在长沙，有两座“名山”，一座是湘江西岸的岳麓山，另一座是浏阳河畔的马栏山。
          </p>
          <p style="text-align:justify;">
            岳麓山只有海拔300米高，却文脉绵延，记载了半部长沙史。而马栏山并不是山，却见证了“电视湘军”崛起史，蜚声海内外。按照省委、省政府决策部署，长沙市精心规划、建设，如今，这两座山都成为了长沙“头脑产业”聚集地。
          </p>
          <p style="text-align:justify;">
            4月28日至30日，参加“壮丽70年·奋斗新时代——推动高质量发展调研行”的中央和省内媒体记者，先后走进岳麓山国家大学科技城与马栏山视频文创园，感受这两座山的“头脑产业”掀起的浪潮。
          </p>

          <p style="text-align:justify;font-weight:600">
            聚集走向复合型，成果转化更便捷
          </p>
          <p style="text-align:justify;">
            在岳麓山脚下的湖南师范大学与湖南大学之间有一条街。
          </p>
          <p style="text-align:justify;">
            11年前，街上有各种极富特色的小吃餐馆，还有旧书店、杂货店、网吧、KTV等，每到夜晚，街上人流拥挤、热闹非凡，成为几代学生的青春记忆。岳麓山国家大学科技城建设启动后，这条街经过政府部门的规划，转型成了桃子湖文化创意产业园，除了街道变得环境优美，更洋溢着浓浓的创新氛围。
          </p>
          <p style="text-align:justify;">
            “传统的机械抓手一般运用于制造业领域，比较僵硬。”在文创园内湖南大学机器人学院，学生朱炳辉现场演示了他研发的“可以抓蛋糕”的柔性机器人——气动抓手。
          </p>
          <p style="text-align:justify;">
            这款自重只有200克的硅胶小触手，却可以抓住重1000克的易碎物品。
          </p>
          <p style="text-align:justify;">
            “机器人抓手由硅胶组成，通过气动控制进行弯曲抓取。”朱炳辉介绍，硅胶柔软的质地和类似皮肤的触感，让柔性机器人在未来可适用于多个领域。而这样一个创新，需要运用自动化、材料、计算机、机械等多方面知识。
          </p>
          <p style="text-align:justify;">
            湖大机器人学院和学校其他学院确有不同，它采取校内二次招生方式，在大一新生中招收电气与自动化、计算机、机械、工业设计、材料、物理、数学等相关专业学生，构建起“教、研、创”人才培养新模式。
          </p>
          <p style="text-align:justify;">
            “传统方式是哪个学院就招收哪方面专业的学生，但在我们学院，有多个专业的学生一起从事研究和创新，多学科交叉融合，从而实现跨界创新。”湖大机器人学院副院长颜亮说。
          </p>
          <p style="text-align:justify;">
            在长沙，文创园是其经济迈向高质量发展的一眼活水。从这里，科研资源源源不断地向创新创意产品转化。
          </p>
          <p style="text-align:justify;">
            现在文创园内，湖南师大众创空间、湖大工业设计院等纷纷进驻，科研成果就地转化，创新创业氛围十分浓厚。同时，在人才培养方面也大胆尝试，从单一型发展变为复合型集聚。
          </p>
        </div>
        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails9')">上一条：岳麓山国家大学科技城：23平方公里的产学研高地</span>
            <span @click="$router.push('/indexOs/newsDetails11')">下一条：中央媒体“长沙高质量发展调研行”启动</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
.preview-news{
  width: 100%;
  height: auto;
  background: #F5F5F5;
  .main{
    width: 800px;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding-top: 58px;
    justify-content: space-between;
    .left{
      flex: 1;
      margin-right: 63px;
      h3{
        color: #313131;
        font-size:24px;
        font-weight: bolder;
        text-align: center;
      }
      .news-setting{
        color: #B5B5B5;
        display: flex;
         font-size:18px;
        .time{
          font-size:18px;
          color: #B5B5B5;
          font-weight: 100;
          width: 100%;
          text-align: center;
        }
        .num{
          font-size:18px;
          margin-left: 30px;
          font-weight: 100;
        }
      }
      .news-content{
        p{
          font-size: 18px;
          color: #313131;
          line-height: 32px;
          text-indent: 36px;
        }
        .img-explain{
          text-align:justify;
          text-align: center;
          font-size:16px;
        }
      }
      .img-box{
        width: 100%;
    display: flex;
    justify-content: center;
        img{
          width:800px;
        }
      }
    }
    .pagination{
      display: flex;
      justify-content: space-between;
      padding: 50px 0 150px;
      border-top: 1px solid #DDDDDD;
      .a-more{
        display: flex;
        flex-direction: column;
        font-size:18px;
        line-height:32px;
        color:rgba(49,49,49,1);
        span{
          display: inline-block;
          width:600px;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .el-button{
        width: 134px;
        height: 48px;
        border: 1px solid rgba(253,156,2,1);
        opacity: 1;
        border-radius: 10px;
        font-size:24px;
        color:rgba(253,156,2,1);
      }
    }
    // 搜索区域
    .search-right{
      width: 334px;
      .el-input{
        width: 329px;
        height: 45px;
      }
      .el-input__inner{
        border-radius: 5px;
      }
      .el-input__icon{
        font-size: 24px;
      }
      .hot-news{
        .hot-title{
          font-size:24px;
          margin-bottom: 12px;
          color:#313131;
          .hot-icon{
            display: inline-block;
            background: url('../../assets/images/news/hot.png');
            width: 18px;
            height: 22px;
            margin-left: 6px;
          }
        }
        .dg-news{
          display: flex;
          margin-bottom: 22px;
          img{
            width:132px;
            height:76px;
          }
          .news-introduce{
            flex: 1;
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .news-title{
              font-size:16px;
              font-weight: 600;
              color: #313131;
              box-sizing: border-box;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              overflow: hidden;
            }
            .time{
              font-size:18px;
              color: #B5B5B5;
              font-weight: 100;
            }
          }
          &:hover{
            .news-title{
              color:rgba(253,156,2,1);
            }
          }
        }
      }
    }
  }
}
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
